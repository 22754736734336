<template>
  <div class="columns justify-center">
    <h1>Redirecting to login...</h1>
  </div>
</template>

<script>
export default {
  mounted () {
    window.location.href = process.env.VUE_APP_DISCORD_AUTH_URI
  }
}
</script>

<style>

</style>
